import React from 'react'

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, selectedImage: undefined};
    this.imageLoaded = this.imageLoaded.bind(this);
    this.clicked = this.clicked.bind(this);
    this.modalRef = React.createRef();
  }

  imageLoaded(){
    this.setState({loaded: true});
  }

  clicked(event, full){
    if(event.target.parentElement.className === 'thumbBar'){
      this.setState({selectedImage: full});
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.setState({loaded: false});
      setTimeout(() => {
        this.props.hideModal();
      }, 125);
    }
  }

  focus(){
    this.modalRef.current.focus();
  }

  render(){
    let images = this.props.images;
    let innerImage = this.state.selectedImage || images[0].full;
    //{images.map((img, i) => { return (<img src={img} href={i} key={i} style={{height: '10vh', marginRight: '1em'}} onClick={this.clicked}/>) })}
    // {images.map((img, i) => { return (<Image thumb={img} style={{height: '10vh', marginRight: '1em'}} fulls={img} effects='' parentStyle={{display: 'inline-block', height: 'auto', width: 'auto'}} onClick={this.clicked} key={i}/>) })}
    return (
      <div className={'modal'+(this.state.loaded ? ' loaded' : '')+(this.props.visible ? ' visible' : '')} tabIndex='-1' ref={this.modalRef} onClick={this.clicked} style={{flexDirection: 'column'}}>
        <div className='inner' style={{margintTop: 'auto'}}>
          <img src={innerImage} onLoad={this.imageLoaded} alt=''/>
        </div>
        {(images.length > 1) &&
          <div className='thumbBar' style={{marginTop: '1em'}}>
            {images.map((img, i) => { return (<img src={img.miniThumb} key={i} style={{height: '10vh', marginRight: '1em'}} onClick={(e) => this.clicked(e, img.full)} alt=''/>) })}
          </div>
        }
      </div>
    );
  }
}

export default Modal
