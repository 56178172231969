import React from 'react'

const Panel = (props) => {
  const { id, size, color, type, typeOptions } = {id: '', size: '', color: '', type: '', typeOptions: '', ...props};

  return (
    <section id={id} className={`panel ${type} ${size} ${color} ${typeOptions}`}>
      {props.children}
    </section>
  );
}

export default Panel
