import React from 'react'

import Panel from './panel'

const Banner = (props) => {
  const { size, color, orientation, children} = {size: '', color: '', orientation: '', ...props};

  return (
    <Panel type='banner' size={size} color={color} typeOptions={orientation}>
      {children}
    </Panel>
  );
}

export default Banner
