import React from 'react'

import Modal from './modal'

class Gallery extends React.Component {
  constructor(props){
    super(props);
    this.state = { modalVisible: false, selectedImages: []};
    this.showImage = this.showImage.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.modalRef = React.createRef();
  }

  showImage(event, images){
    if(event.target.parentElement.localName !== 'picture') return;
    event.preventDefault();
    document.addEventListener("touchmove",
      this.noTouch,
      { passive: false }
    );
    this.setState({renderModal: true, modalVisible: true, selectedImages: Array.isArray(images) ? images : [images]});
    if (this.modalRef.current) this.modalRef.current.focus();
  }

  hideModal(){
    this.setState({modalVisible: false});
    setTimeout(() => {
      this.setState({renderModal: false, selectedImages: ''});
      document.body.focus();
      document.removeEventListener("touchmove",
        this.noTouch,
        { passive: false }
      );
    }, 475);
  }

  noTouch(event){
    event.preventDefault();
  }

  render(){
    return (
      <div className='gallery'>
        { this.state.renderModal ? <Modal visible={this.state.modalVisible} images={this.state.selectedImages} hideModal={this.hideModal} ref={this.modalRef}/> : null }
        {React.Children.map(this.props.children, (child) => React.cloneElement(child, {clickCallback: this.showImage}))}
      </div>
    );
  }
}

const Group = (props) => {
  const { size, children, clickCallback } = {size: '', ...props};

  return (
    <div className={`group ${size}`}>
      {React.Children.map(children, (child) => React.cloneElement(child, {clickCallback: clickCallback}))}
    </div>
  );
}

export default Gallery;
export { Gallery, Group };
