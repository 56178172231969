import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'

import Layout from '../components/layout'

import Panel from '../components/panel'
import Content from '../components/content'
import Image from '../components/image'
import Banner from '../components/banner'
import { Gallery, Group } from '../components/gallery'

if (typeof window !== `undefined`){
  require('../assets/js/main.js')
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      thumbs: allFile(
        filter: {
          relativeDirectory: { eq: "gallery/thumbs" }
        }
      ) {
        edges {
          node {
            base
            name
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      fulls: allFile(
        filter: {
          relativeDirectory: { eq: "gallery/fulls" }
        }
      ) {
        edges {
          node {
            base
            name
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      miniThumbs: allFile(
        filter: {
          relativeDirectory: { eq: "gallery/mini-thumbs" }
        }
      ) {
        edges {
          node {
            base
            name
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  `);

  var images = {};
  data.thumbs.edges.forEach((edge) => {
    images[edge.node.name] = {thumb: edge.node.childImageSharp.fluid};
  });
  data.fulls.edges.forEach((edge) => {
    let image = images[edge.node.name] || (images[edge.node.name] = {});
    image['full'] = edge.node.childImageSharp.original.src;
  })
  data.miniThumbs.edges.forEach((edge) => {
    let image = images[edge.node.name] || (images[edge.node.name] = {});
    image['miniThumb'] = edge.node.childImageSharp.original.src;
  })

  return (
  <Layout>
    <div id='intro' className='shadowDiv'>
      <Helmet>
        <title>Willen is Kunnen</title>
        <meta name='description' content='Koninklijk Blaasorkest Willen is Kunnen Beerst'/>
        <meta name='og:title' content='Koninklijk Blaasorkest Willen is Kunnen Beerst'/>
        <meta name='og:type' content='website'/>
        <meta name='keywords' content='fanfare, blaasorkest, beerst, willen is kunnen'/>
        <meta name='theme-color' content='#50b785'/>
        <meta name="google-site-verification" content="HO3oZ5OT4lNcqbg5eLgyoyTZFokYGdwjYIMEdRX_C6c" />
      </Helmet>

      <Banner orientation='right'>
        <Content size='span-3-75' color='color0'>
          <h2 className='' style={{textAlign: 'left'}}>Koninklijk Blaasorkest</h2>
          <h1 style={{textAlign: 'center', marginTop: '2em', marginBottom: '2em'}}>Willen is Kunnen</h1>
          <h2 style={{textAlign: 'right'}}>Beerst</h2>
        </Content>
      </Banner>
    </div>

    <div className='shadowDiv'>
      <Panel>
        <Gallery>
          <Group size="span-4-5">
            <Image size="span-3" thumb={images.concert_italia} fulls={images.concert_italia} position="bottom" />
            <Image size="span-1-5" thumb={images.concert_spotlight2} fulls={images.concert_spotlight2} position="center" />
            <Image size="span-1-5" thumb={images.concert_spotlight1} fulls={images.concert_spotlight1} position="center" />
            <Image size="span-3" thumb={images.concert_kerst} fulls={images.concert_kerst} position="center" />
          </Group>
        </Gallery>
      </Panel>

      <Panel size='small' orientation='right'>
        <div className="inner color1">
          <h2 className="major">Willen is Kunnen</h2>
          <p>is de lokale muziekvereniging gevestigd in Beerst maar samengesteld uit leden komende van wijde omstreken.</p>
          <p>
            Wekelijks repeteren wij op donderdagavond onder muzikale leiding van dirigent Koen Dewulf.<br></br>
            Alle soorten genres passeren bij ons op de pupiter, daarin is onze dirigent heel flexibel. Jong of oud, voor iedereen wel wat!
          </p>
          <p>
            Het vrolijk samenspel van ons korps wordt steevast georchestreerd zonder valse noten dankzij de inzet van bestuursleden John Vervaecke en Lieselotte Blomme onder het goedkeurend oog van voorzitter Edgard Everaert.
          </p>
          <p>
            Amateurmuzikant, beroepsmuzikant of jong trommel geweld: iedereen is bij ons van harte welkom!
          </p>
        </div>
      </Panel>

      <Panel>
        <Gallery>
          <Group size="span-4-5">
            <Image size="span-3" thumb={images.street_formation} fulls={images.street_formation} position="bottom" />
            <Image size="span-1-5" thumb={images.street_troms} fulls={images.street_troms} position="center" />
            <Image size="span-1-5" thumb={images.street_spotlight} fulls={images.street_spotlight} position="center" />
            <Image size="span-3" thumb={images.street_marching} fulls={images.street_marching} position="bottom" />
          </Group>
          <Image size="span-2-5" thumb={images.street_action} fulls={images.street_action} position="top" />
        </Gallery>
      </Panel>

      <Panel size='small' orientation='left'>
        <div className="inner color2">
            <h2 className="major">Willen is Kunnen</h2>
            <p>
              is niet alleen een vereniging van muzikanten, maar ook van vrienden.
              Ernst tijdens de repetities; veel plezier ervoor, erna en erbuiten.
            </p>
            <p>
              Tussenin muzikale optredens organiseren wij, ondersteund door een fenomenale technische ploeg, geregeld andere activiteiten zoals repititie-dagen, barbecues, kaartingen en busritten.
            </p>
            <p>
              Daarnaast maken we als groep ook al graag eens een extrascholaire uitstap naar muzikale evenementen in de buurt.
            </p>
        </div>
      </Panel>

      <Panel>
        <Gallery>
          <Image size="span-2-5" thumb={images.friends} fulls={images.friends} position="top" />
          <Group size="span-6">
            <Image size="span-1-5" thumb={images.bbc} fulls={images.bbc} only={'desktop'} position="bottom" />
            <Image size="span-3" thumb={images.lunch} fulls={images.lunch} position="center" />
            <Image size="span-1-5" thumb={images.bbc} fulls={images.bbc} only={'mobile'} position="center" />
            <Image size="span-1-5" thumb={images.sint_in_action} fulls={[images.sint_action_1, images.sint_action_2, images.sint_action_3]} position="center" />
            <Image size="span-3" thumb={images.rehearsal} fulls={images.rehearsal} position="center" />
            <Image size="span-3" thumb={images.lineup_before} fulls={[images.lineup_before, images.lineup_after]} position="center" />
          </Group>
        </Gallery>
      </Panel>

      <Panel size='small' orientation='left'>
        <div className="inner color1">
            <h2 className="major">Willen is Kunnen</h2>
            <p>
              werd in 1946 opgericht als fanfare onder impuls van Pastoor Deleersnijder en toekomstig voorzitter Roger Debusschere.
              Dit was een frisse start voor de eerdere Beerstse muziekvereniging die verloren ging tijdens de oorlogsjaren.
            </p>
            <p>
              In 1996 bestond de vereniging 50 jaar, mocht de fanfare zich voortaan Koninklijk noemen
              en werd Edgard Everaert verkozen als nieuwe voorzitter.
              Het dirigeerstokje van Beerst was ondertussen ook al enkele malen van hand gewisseld: langs René Vanoverschelde, Guido Hoste, Luc Debruyne en Mario Spiessens kwam het uiteindelijk bij huidig maestro Koen Dewulf terecht.
            </p>
            <p>
              Tegenwoordig is onze vereniging een Koninklijk Blaaskorkest en is ze samengesteld uit een bonte verzameling leden: van anciens van het eerste uur tot speelvogels die hun eerste muzikale stappen zetten.
            </p>
        </div>
      </Panel>

      <Panel>
        <Gallery>
          <Group size="span-3">
            <Image size="span-3" thumb={images.anciens_diksmuide} fulls={images.anciens_diksmuide} position="center" />
            <Image size="span-1-5" thumb={images.anciens_flag} fulls={images.anciens_flag} position="bottom" />
            <Image size="span-1-5" thumb={images.anciens_vrede} fulls={images.anciens_vrede} position="bottom" />
          </Group>
        </Gallery>
      </Panel>

      <Panel color='color4-alt'>
        <div className="intro span-3 color2">
          <h2 className="major">Contact</h2>
          <p>
            Aarzel zeker niet om ons te contacteren indien u aanvullende informatie wenst,
            dan sturen wij u graag een muzikale mail terug.
          </p>
          <p>
            Of neem een kijkje op onze Facebook pagina, waar nog meer
            foto's van of details omtrent onze evenementen te vinden zijn.
          </p>
          <ul className="contact-icons color1">
            <li className="icon fa-envelope"><a href="mailto:blomme_lieselotte@hotmail.com">blomme_lieselotte@hotmail.com</a></li>
            <li className="icon brands fa-facebook-f"><a href="https://www.facebook.com/groups/173489961681">Willen is Kunnen</a></li>
          </ul>
        </div>
      </Panel>
    </div>
  </Layout>
)
}

export default IndexPage
