import React from 'react'

const Content = (props) => {
  const { size, color } = {size: '', color: '', ...props};

  return (
    <div className={`content ${size} ${color}`}>
      {props.children}
    </div>
  );
}

export default Content
