import React from 'react'
import Img from "gatsby-image"

const Image = (props) => {
  const { fulls, size, effects, position, only, parentStyle, clickCallback } = {size: '', effects: 'filtered', only: '', ...props};
  const { thumb, style } = props;

  return (
    <a className={`image ${size} ${effects} ${only}`} data-position={position} style={parentStyle} onClick={(e) => clickCallback(e, fulls)}>
      <Img fluid={thumb.thumb} alt="" style={{height: '100%',...style}} fadeIn={false}/>
    </a>
  );
}

export default Image
